import { Controller } from 'stimulus'
import Rellax from 'rellax'

export default class extends Controller {
  static targets = ['rellax', 'element']
  static classes = ['hidden']

  initialize () {
    this.thresold = 0.2
  }

  connect () {
    this.initializeRellax()
    this.hideOutsideElements()
    this.makeElementsAppear()
  }

  initializeRellax () {
    if (document.querySelector('.rellax')) {
      const rellax = new Rellax('.rellax', {
        center: true
      })
    }
  }

  hideOutsideElements () {
    this.elementTargets.forEach(element => {
      if (!this.isElementInViewport(element)) {
        element.classList.add(this.hiddenClass)
      }
    })
  }

  makeElementsAppear () {
    const elementsObserver = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > this.thresold) {
          entry.target.classList.remove(this.hiddenClass)
          observer.unobserve(entry.target)
        }
      })
    }, {
      root: null,
      threshold: this.thresold
    })
    this.elementTargets.forEach(element => elementsObserver.observe(element))
  }

  isElementInViewport (el) {
    var rect = el.getBoundingClientRect()
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&     
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    )
  }
}